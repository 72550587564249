import React, { useState, useEffect } from "react"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

import Loader from "react-loader-spinner"

import PlaySVG from "../../scss/img/icon/sibsvg/sib-icon-theatre.svg"

export default function SignupForm(props) {
  const identity = useIdentityContext()
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" })

  const [formError, setFormError] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const [isTeacher, setIsTeacher] = useState(false)
  const [allowMarketing, setAllowMarketing] = useState(true)

  useEffect(() => {
    if (props.providedEmail && props.providedEmail.length > 0) {
      if (getValues("email").length === 0) {
        setValue("email", props.providedEmail, { shouldValidate: false })
      }
    }
  }, [props.providedEmail, getValues, setValue])

  const capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  const onSubmit = async (data) => {
    var firstName = capitalizeFirstLetter(data.first_name);
    var submitData = {
      email: data.email,
      password: data.password,
      user_metadata: {
        full_name: `${firstName} ${data.last_name}`,
        first_name: firstName,
        last_name: data.last_name,
        school_name: data.school_name,
        is_teacher: isTeacher,
        allowMarketing: allowMarketing,
      },
    }
    setSigningUp(true)
    setFormError(false)

    identity
      .signup(submitData)
      .then(() => {
        setSigningUp(false)
        navigate("/login")
      })
      .catch((e) => {
        setFormError(e.message)
        setSigningUp(false)
      })
  }

  const toggleIsTeacher = () => {
    setIsTeacher(!isTeacher)
  }

  const toggleAllowMarketing = () => {
    setAllowMarketing(!allowMarketing)
  }

  const passwordConfirmValidate = () => {
    // Normal validation
    let password = getValues("password")
    let passwordConfirm = getValues("password_confirm")
    if (password === passwordConfirm) {
      return true
    } else {
      return "Passwords must match"
    }
  }

  const schoolNameValidate = () => {
    if (!isTeacher) {
      return true
    }
    let schoolName = getValues("school_name")
    if (schoolName.length === 0) {
      setError(
        "school_name",
        {
          type: "required",
          message: "required",
        },
        { shouldFocus: true }
      )
      return "required"
    }
    if (schoolName.length < 8) {
      setError(
        "school_name",
        {
          type: "minLength",
        },
        { shouldFocus: true }
      )
      return "minLength"
    }
    if (schoolName.length > 100) {
      setError(
        "school_name",
        {
          type: "maxLength",
        },
        { shouldFocus: true }
      )
      return "maxLength"
    }
    return true
  }

  return (
    <>
      {formError && (
        <div className="notification-error-text py-1">{formError}</div>
      )}
      <div className={`${signingUp ? "is-hidden" : ""}`}>
        <form id="signup-submit-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="">Email</div>
          <div className="pb-1 is-size-7">
            This will be your login name. Please ensure you have access to this
            address for validation.
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                {...register("email", {
                  required: true,
                  maxLength: 100,
                  minLength: 5,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                className="input"
                type="text"
                placeholder="Email"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="notification-error-text">
                {errors.email && errors.email.type === "required" && (
                  <span>Email is required</span>
                )}
                {errors.email && errors.email.type === "maxLength" && (
                  <span>Max Length Exceeded (100)</span>
                )}
                {errors.email && errors.email.type === "minLength" && (
                  <span>Minimum 5 Characters</span>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <span>Please enter a valid Email address</span>
                )}
              </span>
            </p>
          </div>
          <div className="pb-1">First Name(s)</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                {...register("first_name", {
                  required: true,
                  minLength: 2,
                  maxLength: 50,
                })}
                className="input"
                type="text"
                placeholder="First Name"
              />
              <span className="icon is-small is-left">
                <PlaySVG fill="#c0c0c0" />
              </span>
              <span className="notification-error-text">
                {errors.first_name && errors.first_name.type === "required" && (
                  <span>First Name is required</span>
                )}
                {errors.first_name && errors.first_name.type === "minLength" && (
                  <span>Minimum 2 Characters</span>
                )}
                {errors.first_name && errors.first_name.type === "maxLength" && (
                  <span>Max Length Exceeded (50)</span>
                )}
              </span>
            </p>
          </div>
          <div className="pb-1">Last Name(s)</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                {...register("last_name", {
                  required: true,
                  minLength: 2,
                  maxLength: 50,
                })}
                className="input"
                type="text"
                placeholder="Last Name"
              />
              <span className="icon is-small is-left">
                <PlaySVG fill="#c0c0c0" />
              </span>
              <span className="notification-error-text">
                {errors.last_name && errors.last_name.type === "required" && (
                  <span>Last Name is required</span>
                )}
                {errors.last_name && errors.last_name.type === "minLength" && (
                  <span>Minimum 2 Characters</span>
                )}
                {errors.last_name && errors.last_name.type === "maxLength" && (
                  <span>Max Length Exceeded (50)</span>
                )}
              </span>
            </p>
          </div>
          <div className="">Password</div>
          <div className="pb-1 is-size-7">Minimum 8 Characters</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password", {
                  required: true,
                  minLength: 8,
                  maxLength: 100,
                })}
                type="password"
                placeholder="Password"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password && errors.password.type === "required" && (
                  <span>Password is required</span>
                )}
                {errors.password && errors.password.type === "maxLength" && (
                  <span>Max Length Exceeded (100)</span>
                )}
                {errors.password && errors.password.type === "minLength" && (
                  <span>Minimum 8 Characters</span>
                )}
              </span>
            </p>
          </div>
          <div className="pb-1">Confirm Password</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password_confirm", {
                  validate: passwordConfirmValidate,
                })}
                type="password"
                placeholder="Confirm Password"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password_confirm && errors.password_confirm.message}
              </span>
            </p>
          </div>
          <div className="">Are you a member of an academic institution?</div>
          <div className="pb-1 is-size-7">
            (e.g. A teacher at a school, a department head, a district I.T.
            Administrator)
          </div>
          <div className="field">
            <p className="control">
              <label className="switch is-rounded">
                <input
                  className="input"
                  {...register("is_teacher", {})}
                  type="checkbox"
                  placeholder="Are you a teacher?"
                  value={isTeacher}
                  checked={isTeacher}
                  onChange={(e) => toggleIsTeacher(e)}
                />
                <span className="check is-sibblack" />
              </label>
              <span className="is-teacher-toggle-text is-size-7">
                {isTeacher ? "Yes" : "No"}
              </span>
            </p>
          </div>
          <div className={`${isTeacher ? "" : "is-hidden"}`}>
            <div className="pb-1">Name of School or Institution</div>
            <div className="field">
              <p className="control">
                <input
                  className="input"
                  {...register("school_name", {
                    validate: schoolNameValidate,
                  })}
                  type="text"
                  placeholder="School / Institution Name"
                />
                <span className="notification-error-text">
                  {errors.school_name &&
                    errors.school_name.message === "required" && (
                      <span>School Name is required</span>
                    )}
                  {errors.school_name &&
                    errors.school_name.message === "maxLength" && (
                      <span>Max Length Exceeded (100)</span>
                    )}
                  {errors.school_name &&
                    errors.school_name.message === "minLength" && (
                      <span>Minimum 8 Characters</span>
                    )}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-2">
            Allow Shakespeare In Bits to send you marketing or promotional
            Emails?
          </div>
          <div className="field mt-1">
            <p className="control">
              <label className="switch is-rounded">
                <input
                  className="input"
                  {...register("allow_marketing", {})}
                  type="checkbox"
                  placeholder="Allow Marketing?"
                  value={allowMarketing}
                  checked={allowMarketing}
                  onChange={(e) => toggleAllowMarketing(e)}
                />
                <span className="check is-sibblack" />
              </label>
              <span className="is-teacher-toggle-text is-size-7">
                {allowMarketing
                  ? "Yes, I'm okay with receiving marketing Emails from Shakespeare In Bits"
                  : "No, I do not wish to receive marketing Emails from Shakespeare In Bits"}
              </span>
            </p>
          </div>
          <button className="button is-black is-fullwidth mt-4" type="submit">
            Sign Up
          </button>
        </form>
      </div>
      <div className={`${signingUp ? "" : "is-hidden"}`}>
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          Signing Up - A Moment, Please
        </div>
      </div>
    </>
  )
}
