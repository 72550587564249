import React, { useEffect, useState } from "react"
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"
import { useSubInfoContext } from "../services/subinfocontext"
import Layout from "../components/common/layout/layout"
import SignupForm from "../components/user/signupform"
import { navigate } from "gatsby"
//import "../styles/mystyles.scss"

const SIBSignupPage = ({ location }) => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()
  const [providedEmail, setProvidedEmail] = useState()

  // renavigate students once logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (subInfoContext.subInfo.isStudentCode) {
        navigate("/studenthome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  // and users once logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (identity.user) {
        navigate("/app/userhome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  useEffect(() => {
    if (location && location.state) {
      if (location.state.email && location.state.email.length > 0) {
        setProvidedEmail(location.state.email)
      }
    }
  }, [location])

  return (
    <Layout>
      <SIBSignupHeader />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            <SignupForm providedEmail={providedEmail}/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const SIBSignupHeader = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Sign Up</h1>
              <p>Signing up gives you instant free access to sample scenes and resources from all plays</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBSignupPage
